import { Order } from "@commercetools/platform-sdk";
import { DictionaryStates, SearchStatus, mapStatesOrders } from "../interfaces/ITableCard";
import { notifyError } from "../components/toast/Toast";

export interface IStatusCustom {
    company: string;
    dateEstatusChanged: string;
    delivery_data: IDelivery[];
    estatus: string;
    idEstatus: string;
    idOrden: string;
    sucursalId: string
}

export interface IDelivery {
    delivery_date: string;
    sku: string;
} 

const nameCustomField = "Descripcion-estatus"

export const DefineStatusCommercetools = (order: Order) => {
    if(!order?.custom?.fields?.[nameCustomField] || order?.custom?.fields?.[nameCustomField]?.length <= 0 ) return SearchStatus[90]
    const newCustom: string = order?.custom?.fields["Descripcion-estatus"].replace(/'/g, '"');
    if(!newCustom.includes('[')) return SearchStatus[90]
    const jsonParse = JSON.parse(newCustom)
    const idStatus = jsonParse[0].idEstatus || 0
    //@ts-ignore
    return SearchStatus[idStatus]
}

export const GetStatusByOrderLess = (order: Order) => {
    //SI hay un estatus cancelado en commercetools le vale lo demas 
    if(order.orderState == "Cancelled") return SearchStatus[30]
    if(order.shipmentState == "Backorder") return SearchStatus[100]
    if(!order?.custom?.fields?.[nameCustomField] || order?.custom?.fields?.[nameCustomField]?.length <= 0 ) return SearchStatus[90]
    const newCustom: string = order?.custom?.fields["Descripcion-estatus"].replace(/'/g, '"');
    if(!newCustom.includes('[')) return SearchStatus[90]
    const jsonParse: IStatusCustom[] = JSON.parse(newCustom)
    let idStatus: number = 500;
    for( const statusCustom of jsonParse ){
        if(parseInt(statusCustom.idEstatus) < idStatus) idStatus = parseInt(statusCustom.idEstatus)
    }
    //@ts-ignore
    return SearchStatus[idStatus]
}

export const DefStatusOrder = (order: Order) => {
    if (!order) return mapStatesOrders["Cargando"]
    if (order.orderState == "Complete") return mapStatesOrders[order.shipmentState ?? order.orderState]
    if (!order.custom?.fields["Descripcion-estatus"] || order.custom?.fields["Descripcion-estatus"].length <= 0) return mapStatesOrders[order.orderState]
    const newCustom: string = order?.custom?.fields["Descripcion-estatus"].replace(/'/g, '"');
    if (!newCustom.includes('[')) return mapStatesOrders[newCustom]
    const jsonParse: IStatusCustom[] = JSON.parse(newCustom)
    const valor = CountStatus(jsonParse)
    const status = {...mapStatesOrders[valor.estatus || "Open"]}
    if (valor.maxRepeticiones != jsonParse.length) status.name += " parcial"
    return status
}

interface StatusCount {
    [key: string]: number;
}

const CountStatus = (statusCustom: IStatusCustom[]) => {
    const conteo: StatusCount = statusCustom.reduce((acc, cadena) => {
        acc[cadena.estatus] = (acc[cadena.estatus] || 0) + 1;
        return acc;
    }, {} as StatusCount);

    const arrayObject = Object.entries(conteo);
    if (arrayObject.length === 1) return {
        estatus: arrayObject[0][0],
        maxRepeticiones: arrayObject[0][1]
    };
    let estatus: string | null = null;
    let maxRepeticiones = 0;
    for (const [valor, repeticiones] of arrayObject) {
        if (repeticiones > maxRepeticiones) {
            maxRepeticiones = repeticiones;
            estatus = valor;
        }
    }

    return {
        estatus,
        maxRepeticiones
    };
};