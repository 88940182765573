import React, { ReactElement, ReactNode } from 'react';
import styles from './CardOrdenesCompras.module.scss';
import { ICard, ICardOrdenesCompras } from '@/shared/interfaces/ICardOrdenesCompras';

//also known as card solicitudes
const CardOrdenesCompras = (props: {
  title: string;
  icon: ReactElement;
  cards: ICardOrdenesCompras[];
  redirectsTo: string;
  emptyStateLabel: string;
  emptyStateIcon: SVGElement | ReactNode;
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>
        <p>{props.title || 'TITLE'}</p>
        <span>{props.icon || 'ICON'}</span>
      </div>
      <div className={styles['cards-container']}>
        <>
          {props.cards.map((cardEl) => (
            <div className={styles['card']}>
              <div className={styles['value']}>
                <div className={styles['circle']} />
                {cardEl.main}
              </div>
              <div>
                <div>
                  {cardEl.texts.map((textObj: ICard) => (
                    <>
                      <p>
                        <span>{textObj.label}</span> <span style={{ color: 'black' }}>{textObj.value}</span>
                      </p>
                    </>
                  ))}
                </div>
              </div>
              <div>
                <a href={cardEl.redirectsTo}>Ver detalle</a>
              </div>
            </div>
          ))}
          {props.cards.length === 0 && (
            <div className={styles['empty-state-container']}>
              {/* empty state */}
              <div>{props.emptyStateIcon}</div>
              <div>{props.emptyStateLabel}</div>
            </div>
          )}
        </>
      </div>
      <div>{props.cards.length > 0 && <a href={props.redirectsTo}>Ver todos{'>'}</a>}</div>
    </div>
  );
};

export default CardOrdenesCompras;
