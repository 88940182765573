import React, { useContext, useState } from 'react';
import ButtonAdd from '../buttonAdd/ButtonAdd';
import { rol } from '@/shared/roles/featureFlags';
import styles from './styles.module.scss';
import { Customer } from '@commercetools/platform-sdk';
import Button from '@/shared/components/button/Button';
import { ICustomBusinessUnit, addProductToCartWithShippingAddress } from '@/shared/functions/commerceTools';
import { notifyError, notifySuccess } from '../toast/Toast';
import { AppContext } from 'context/appContext/AppContextProvider';

const ProductButton = ({
    customer,
    setShowModal,
    setSelectedProduct,
    setQuantityRelated,
    product,
    branch,
    setIsLoading,
    isLoading,
    buttonLabel = "Añadir a carrito",
    selectedProduct
}: {
    customer: Customer | null;
    setShowModal: any;
    setSelectedProduct: any;
    setQuantityRelated: any;
    product: any;
    branch?: ICustomBusinessUnit | null;
    setIsLoading: any;
    isLoading: boolean;
    buttonLabel?: string;
    selectedProduct: any;
}) => {

    const context = useContext(AppContext)
    const [quantity, setQuantity] = useState(1);
    return (
        <>
            {rol[customer?.custom?.fields.roles] && !rol[customer?.custom?.fields.roles]['haveBranches'] && (
                <div className={styles['item-add']}>
                    <ButtonAdd
                        style={{ height: '26px', width: '142px' }}
                        quantity={quantity}
                        onAdd={() => {
                            setQuantity(quantity + 1);
                        }}
                        onRemove={() => {
                            setQuantity(quantity - 1);
                        }}
                    />
                </div>
            )}

            <div className={styles['item-button']}>
                <Button
                    onClick={async () => {
                        if (isLoading) return;
                        if (rol[customer?.custom?.fields.roles] && !rol[customer?.custom?.fields.roles]['createOrders']) {
                            notifyError('no tienes permisos para realizar esta acción');
                            return;
                        }
                        if (rol[customer?.custom?.fields.roles]['haveBranches']) {
                            setShowModal(true);
                            setSelectedProduct(product);
                            setQuantityRelated(product?.attributes?.find((item: any) => item.name === "minimalquantity")?.value || 1)
                            debugger
                            console.log(product, "ProductosState")
                            return;
                        }
                        if (!branch) {
                            notifyError('no se ha cargado la unidad de negocio');
                            return;
                        }
                        setIsLoading(true);

                        const res = await addProductToCartWithShippingAddress({
                            quantity,
                            product,
                            addressKey: branch.bussinessUnit.key,
                            variantId: product.variantId,
                        });
                        setIsLoading(false);
                        if (!res.cart) {
                            notifyError('no se pudo agregar al carrito: ' + res.errMessage);
                            return;
                        }
                        notifySuccess('producto añadido al carrito');
                        setShowModal(false)
                        context.setCart(res.cart)
                    }}
                    style={{ height: '30px', width: '142px' }}
                    variant={isLoading ? 'disabled' : 'primary'}
                >
                    {buttonLabel}
                </Button>
            </div>
        </>
    );
};

export default ProductButton;
