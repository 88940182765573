import React, { useContext, useRef } from 'react';
import { Slider } from '@/shared/components/slider/Slider';
import { Customer } from '@commercetools/platform-sdk';
import { ICustomBusinessUnit } from '@/shared/functions/commerceTools';
import { AppContext } from 'context/appContext/AppContextProvider';

const Productos = ({ customer,bussinessUnits }: { customer: Customer |null,bussinessUnits:ICustomBusinessUnit[] | undefined }) => {
  const context = useContext(AppContext);
  const sliderRef = useRef<null | HTMLParagraphElement>(null);
  return (
    <Slider
      sliderRef={sliderRef}
      buttonTitle="Agregar"
      items={context.appContext.newProductsSlider}
      title="Nuevos productos"
      variant="button"
      customer={customer}
    />
  );
};

export default Productos;
