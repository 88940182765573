import React, { useState } from 'react';
import ContenedorSimple from '@/shared/components/contenedorSimple/ContenedorSimple';
import { ListaCompras, ListaComprasEmpty } from '@/shared/icons';
import CustomGrid from '@/shared/components/grid/CustomGrid';
import { EmptyState } from '@/shared/components/grid/EmptyState';
import Link from 'next/link';
import type { IShoppingListDataGrid } from './Home';

const HomeShoppingList = ({ rowData }: { rowData: IShoppingListDataGrid[] }) => {
    const [columnDefs] = useState([
        { field: 'name', headerName: 'Nombre de lista', type: 'rightAligned', lockPosition: 'left', unSortIcon: false },
        {
            field: 'articles',
            headerName: 'Artículos',
            lockPosition: 'left',
            unSortIcon: false,
            cellStyle: {
                textAlign: 'center',
            },
        },
        { field: 'total', headerName: 'Total', type: 'rightAligned', lockPosition: 'left', unSortIcon: false },
    ]);

    return (
        <>
            <ContenedorSimple>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h3 style={{ fontSize: '1.1rem', color: '#002554', fontWeight: 'bold' }}>Lista de compras</h3>
                        <div style={{ marginLeft: 'auto' }}>
                            <ListaCompras />
                        </div>
                    </div>
                    <div >
                        <CustomGrid
                            rowData={rowData}
                            columnDefs={columnDefs}
                            height={395}
                            // @ts-ignore
                            noRowsOverlayComponent={EmptyState}
                            noRowsOverlayComponentParams={{
                                icon: <ListaComprasEmpty />,
                                text: 'Aún no tienes listas de compra, puedes crear una desde tu carrito o tu historial de compra.',
                            }}
                            suppressPaginationPanel={true}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end", marginTop:"20px" }}>
                        <Link style={{ marginLeft: 'auto' }} href="/shoppinglist">
                            {'Ver detalle>'}
                        </Link>
                    </div>
                </div>
            </ContenedorSimple>
        </>
    );
};

export default HomeShoppingList;
