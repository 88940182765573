import React from 'react';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import { ICategorie } from '@/shared/interfaces/ICategories';
import Skeleton from '@/shared/components/skeleton/Skeleton';

const CardCategorie = ({ name, imageSource, id }: ICategorie) => {
  const router = useRouter();

  const handleRedirect = (id: string) => {
    router.push(`/search?id=${id}`);
  };

  const isLoaded=true

  return (
    <>
      <div className={styles['card']} onClick={() => handleRedirect(id)}>
        <Skeleton isLoaded={isLoaded}>
          <>
            <h2 className={styles['title']}>{name}</h2>
            <div>
              <img width="100%" loading='lazy' alt="img categorie" src={imageSource} />
            </div>
          </>
        </Skeleton>
      </div>
    </>
  );
};

export default CardCategorie;
