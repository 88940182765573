import CustomGrid from '@/shared/components/grid/CustomGrid';
import { ShoppingCartEmptyIcon } from '@/shared/icons';
import React, { useState } from 'react';
import { IOrderData } from './Home';
import { EmptyState } from '@/shared/components/grid/EmptyState';
import orderStyles from "@/shared/components/ordersView/styles.module.scss"


const HomeComprasB2BGrid = ({ rowData }: { rowData: IOrderData[] }) => {
    const [columnDefs] = useState([
        {
            field: 'orderNumber',
            headerName: 'ID orden de venta',
            minWidth: 150,
            type: 'leftAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
        {
            field: 'status',
            headerName: 'Estatus de orden',
            cellRenderer: (params: any) => {
                return (
                    <div className={orderStyles['cell']}>
                        <div className={orderStyles[params.data.colorStatus]} />
                        {params.value}
                    </div>
                );
            },
            minWidth: 210,
            type: 'leftAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
        {
            field: 'articles',
            headerName: 'Artículos',
            minWidth: 150,
            cellStyle: {
                textAlign: 'center',
            },
            lockPosition: 'left',
            unSortIcon: true,
        },
        {
            field: 'total',
            headerName: 'Total',
            comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
                if (nodeA.data.totalNumber == nodeB.data.totalNumber) return 0;
                return nodeA.data.totalNumber > nodeB.data.totalNumber ? 1 : -1;
            },
            minWidth: 150,
            type: 'rightAligned',
            lockPosition: 'left',
            unSortIcon: true,
        },
    ]);
    return (
        <>
            <CustomGrid
                columnDefs={columnDefs}
                // @ts-ignore
                noRowsOverlayComponent={EmptyState}
                noRowsOverlayComponentParams={{
                    icon: <ShoppingCartEmptyIcon />,
                    text: 'Aún no tienes productos en tu carrito.',
                }}
                rowData={rowData} height={240} suppressPaginationPanel={true}
            />
        </>
    );
};

export default HomeComprasB2BGrid;
