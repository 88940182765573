import React from 'react';

import Table from 'react-bootstrap/Table';

import { ComprasB2BIconBlue, ListaComprasEmpty, ShoppingCartEmptyIcon } from '@/shared/icons';

import styles from './styles.module.scss';
import { ITableCard } from '@/shared/interfaces/ITableCard';
import HomeComprasB2BGrid from 'frontastic/tastics/b2b/home/HomeComprasB2BGrid';

const ComprasB2b = ({ Svg, OrderStatus, ordersData }: ITableCard) => {
  return (
    <>
      <div className={styles['contenedor']}>
        <div className={styles['contenedor-titulos']}>
          <a href={'/orders'} style={{ textDecoration: 'none' }}>
            <p className={styles['texto-ecommerce']}>Mis compras</p>
          </a>
          {Svg}
        </div>
        <div className={styles['cotenedor-ordenes']}>
          {OrderStatus?.map((item, index) => {
            return (
              <div key={index} className={styles['contenedor-status']}>
                <div className={styles[item.Color]}></div>
                <div className={styles['contenedor-align-horizontal']}>
                  <div className={styles['order-number']}>
                    <p className={styles['text-number']}>{item.Number}</p>
                  </div>
                </div>
                <div>
                  <p className={styles['text-ordenes']}>{item.TypeOrder}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <HomeComprasB2BGrid rowData={ordersData} />
        </div>
        <div className={styles['contenedor-vertodos']} style={{ color: '#165EEB' }}>
          <a href={'/orders'} style={{ color: '#165EEB' }}>
            Ver detalle &gt;
          </a>
        </div>
      </div>
    </>
  );
};

export default ComprasB2b;
